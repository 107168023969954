.NavBar{
	height: 80px;
	background-color: #275C97;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 3vw;
	padding-right: 3vw;
}

.insideNav{
	display: flex;
	border-radius: 5px;
	width: 30vw;
	justify-content: space-around;
	background-color: #FEFFFF;
}

h2{
	font-weight: normal;
	color: white;
	font-size: 18px;
}

#logo{
	height: 60px;
}

.insideNav h2{
	color: black;
}

#roundedText{
	background-color: #FF8F6F;
	padding: 10px;
	border-radius: 5px;
}

.navToggle {
  display: none;
  font-size: 2em;
  cursor: pointer;
  background: #FF8F6F;
  border: none;
  border-radius: 5px;
  color: white;
  align-items: center;
}

.hideToggleBar, .showToggleBar{
	opacity: 0;
	visibility: hidden;
	z-index: 9999;
	max-height: 0;
	width: ;
	overflow: hidden;
	transition: opacity 0.8s ease, visibility 0.8s ease, max-height 0.8s ease;
	background-color: #275C97;
	padding-left: 4vw;
	padding-bottom: 1vw;
	margin-bottom: 0px;
	position: absolute;
	border-top: 2px solid black;
	max-width: 100%;
}

.showToggleBar{
	max-height: 1000px;
}

.isNavOpen{
	max-width: 100vw;
}

@media only screen and (max-width: 850px){
	.navToggle{
		display: block;
	}
	.showTextNavBar, .navBarLink{
		display: none;
	}
	.showToggleBar{
	    opacity: 1;
	    visibility: visible;
	    max-height: 1000px;
	    z-index: 9999;
	}
}