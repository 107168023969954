.Faq{
	padding-left: 5vw;
	padding-right: 5vw;
}

.Faq h1{
	padding: 0;
	padding-top: 5vh;
	margin: 0;
	color: #202E51;
}

.Faq p{
	padding: 0;
	margin: 0;
	padding-top: 10px;
	font-weight: 600;
	color: #202E51;
}

.faqQuestions{
	display: grid;
	gap: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.questions h1{
	margin: 0;
	padding: 0;
}

.questionsTitle{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.questionsTitle p{
	margin: 0;
	padding: 0;
	color: white;
	background-color: #275c97;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	text-align: center;
	align-items: center;
	cursor: pointer;
	font-weight: 600;
}

.questions{
	padding: 20px;
	border-radius: 10px;
	background-color: white;
	height: auto;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
	animation: 0.8s;
}

