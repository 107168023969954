.resource {
    display: flex;
    flex-direction: column;
    padding-left: 5vw;
    padding-right: 5vw;
    /* Stack items vertically */
    justify-content: center;
    /* Center items horizontally */
    align-items: center;
    padding-bottom: 20px;
}

.generalResource,
.setCommon,
.prepareForMentorship {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-top: 40px;
    box-sizing: border-box;
}

.resource h1{
    padding: 0;
    margin: 0;
    color: #202E51;
}

.resource p{
    font-weight: 600;
}

.resource a {
    color: #202E51;
    font-weight: 600;
}

.generalResource {
    background-color: #FCC69B;
    /* Yellow */
}

.setCommon {
    background-color: #8FBAB1;
    /* Green */
}

.prepareForMentorship ol{
    font-weight: 600;
}

.prepareForMentorship span{
    font-weight: normal !important;
}

.prepareForMentorship {
    background-color: #FF708E;
    /* Pink */
}