.MentorProfile{
	background-color: #F4F8FE;
	max-width: 300px;
	border-radius: 10px;
	padding: 15px;
	margin-bottom: 20px;
	margin-right: 10px;
}

.profileAndName{
	align-items: center;
	text-align: center;
}

.profileAndName img{
	max-width: 100px;
	border-radius: 50%;
}

.profileAndName p{
	margin-top: 5px;
	font-weight: 600;
}

.MentorProfile a{
	font-weight: 600;
	color: black;
}

.MentorProfile button{
	border: none;
	background-color: #FF8F6F;
	padding: 10px;
	width: 100%;
	font-size: 18px;
	color: white;
	font-weight: 600;
	border-radius: 20px;
}