.Mentors{
	background-color: #CFE4EF;
	padding-left: 5vw;
	max-width: 100%;
	padding-top: 10vh;
	padding-right: 5vw;
	padding-bottom: 20px;
}

.findAMentorDescription{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 20px;
}

.mentorsDescriptionText{
	flex: 1;

} 

.mentorsDescriptionText h1{
	margin: 0;
	padding: 0;
	color: #202E51;
} 

.findAMentorDescription img{
	max-width: 20vw;
	height: auto;
}

.searchMentors{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 30px;
	max-width: 100%;
}

.searchBar{
	display: flex;
}

.searchBar input{
	width: 400px;
	background-color: transparent;
}

input:focus {
  outline: none;
}

.searchBar img{
	background-color: #202E51;
	margin-left: -10px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

#universityName{
	width: 100%;
	padding: 5px;
	max-width: 100%;
	border-radius: 10px;
	border: 2px solid #202E51;
	font-size: 20px;
}

.schoolLogos{
	display: flex;

}

.comingSoonBanner img{
	max-width: 100%;
}

.schoolLogos img{
	max-height: 400px;
}

@media only screen and (max-width: 850px){
	.findAMentorDescription img{
		max-width: 100%;
	}
	.searchMentors{
		justify-content: center;	
	}
}