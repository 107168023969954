.BottomBar{
	padding-top: 20px;
	padding-left: 5vw;
	padding-right: 5vw;
	display: flex;
	background-color: #275C97;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 20px;
	color: white;
	padding-bottom: 20px;
}

.BottomBar h1{
	font-size: 25px;
}

.leftPortionBottomBar p{
	font-weight: bold;
	margin-top: -5px;
	text-align: center;
}

.leftPortionBottomBar img{
	max-width: 20vw;
}

.middlePortionBottomBar p{
	cursor: pointer;
}

.socialsLogo {
	display: flex;
	align-items: center;
	justify-content: center;
}
.socialsLogo img{
	max-height: 25px;
	margin-right: 10px;
}

.form{
	display: grid;
}

.form p{
	text-align: center;
	color: white;
}

.form input, .form textarea{
	height: 20px;
	width: 20vw;
	padding: 5px;
/*	font-size: 20px;*/
	outline: none;
	background: none;
	border: 1px solid white;
	border-radius: 5px;
	margin-bottom: 15px;
	color: white;
}

.form ::placeholder {
  color: white;
  opacity: 1;
}

.form button{
	height: 25px;
	color: black;
	background: white;
	border: 1px solid #c6c6c6;
	border-radius: 5px;
	font-size: 15px;
}

.bottomBarLink{
	text-decoration: none;
	color: white;
}

@media only screen and (max-width: 900px){
	.BottomBar {
		flex-direction: column;
	}
	.leftPortionBottomBar, .middlePortionBottomBar ,.rightPortionBottomBar{
		max-width: 100%;
		align-items: center;
		text-align: center;
	}
	.leftPortionBottomBar img{
		max-width: 40vw;
	}
	.form input, .form textarea{
		width: 80vw;
	}
}